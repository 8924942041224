const config = {
  platforms: {
    SA: {
      UI_URL : 'https://sa.reiformslive.com.au',
      API_URL: 'https://sa.api.reiformslive.com.au'
    },
    NT: {
      UI_URL : 'https://nt.reiformslive.com.au',
      API_URL: 'https://nt.api.reiformslive.com.au'
    },
    TAS: {
      checklistEnabled: true,
      UI_URL : 'https://tas.reiformslive.com.au',
      API_URL: 'https://tas.api.reiformslive.com.au'
    },
    VIC: {
      UI_URL : 'https://vic.formslive.com.au',
      API_URL: 'https://vic.api.formslive.com.au',
      theme: {
        icon: 'fl-icon-inverted.png',
        logo: 'fl-logo.png',
        'Theme-Color-Primary': '#4884ee',
        'Theme-Button-Primary': '#4884ee'
      }
    }
  },
  livesign: {
    scriptId: '__livesign_widget',
    url: 'https://widget.verify.live-sign.com'
  }
};

export default config;
